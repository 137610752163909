import React, { useCallback } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { getLanguageParam } from '../../hooks/languageUtils';

const MyChartModalStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, .72);
    width: 100vw;
    height: 100vh;
    z-index: 999999;

    .close-btn {
        position: absolute;
        right: 1.625rem;
        top: 1.625rem;
        &:hover {
            cursor: pointer;
        }

        svg {
            width: 1.250rem;
            height: 1.250rem;
        }
    }

    .content-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 663px;
        max-width: 95%;
        min-height: 650px;
        background-color: #fff;
        border-radius: 8px;

        &-inner {
            width: 100%;
            margin: 3.875rem auto 0 auto;
            padding: .25rem;
            @media (min-width: 769px) {
                width: 67%;
            }
        }
    }

    .content-title {
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: #63666A;
        padding: 0 .5rem 0 .5rem;
        @media (min-width: 769px) {
            padding: 0;
        }
    }

    .content-description {
        margin: 2rem 0 0 0;
        font-weight: 400;
        color: #707070;
    }

    .content-link {
        color: #1175A7;
        font-weight: 700;
        text-decoration: underline;
    }

    iframe {
        width: 100%;
        min-height: 375px;
        overflow: hidden;
        border: none;
        @media (min-width: 481px) {
            margin-top: 2.875rem;
        }
    }

    .hide-focus:focus {
        outline: none;
    }
`;

export default function MyChartModal(props) {
    const { setIsModalOpen } = props;
    const languageParam = getLanguageParam();

    function closeModal() {
        setIsModalOpen(false);
    }

    function updateFocus() {
        const title = document.querySelector('#firstItem');
        title.focus();
    }

    function sendFocusBackToFirstItem (e) {
        const title = document.querySelector('#firstItem');
        title.focus();
    }

    return(
        <MyChartModalStyled onClick={closeModal}>
            <div className="content-container" onClick={e => e.stopPropagation()} role="dialog" aria-modal="true" onLoad={updateFocus}>
                <span className="close-btn" onClick={closeModal} role="button" aria-label="Close sign-in overlay">
                    <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.02399 1.5L22.024 21.5" stroke="#626262" stroke-width="3" stroke-linecap="round"/>
                        <path d="M22.024 1.5L2.02399 21.5" stroke="#626262" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                </span>
                <div className="content-container-inner">
                    <div id="firstItem" className="content-title" tabIndex="0">
                        {i18next.t('mychart_modal_blurb')}
                    </div>
                    <div className="content-description">
                        <a href={"https://livewell.aah.org/chart/billing/guestpay/" + languageParam}  className="content-link" rel="noreferrer" target="_blank"
                            aria-label={"Make a secure online payment - no account or sign-in required. Link opens in a new tab"} tabIndex="0">
                            {i18next.t('make_a_secure_online_payment')}
                        </a> {i18next.t('no_account_or_sign_in')} 
                    </div>
                    <iframe src={`${process.env.REACT_APP_MYCHART_IFRAME_URL}/Authentication/Login/LoginWidget` + languageParam}
                            title="MyChartFrame"
                            allowTransparency="true" 
                            target="_parent" 
                            scrolling="no"
                            frameBorder="0">
                    </iframe>
                </div>
                
                {/* placeholder elemt for keyboarding accessibility
                    once element receives focus, focus is sent back to time item so tab does not leave modal */}
                <span id="lastItem" tabIndex="0" onFocus={sendFocusBackToFirstItem}></span>
                
            </div>
        </MyChartModalStyled>
    );
}